
import React, {useState, useEffect} from 'react';

function LoftyCountDown ({
  startfrom, componentTimer,
  className, id,
}) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [timeExpired, setTimeExpired] = useState(false);

  function calculateTimeLeft() {
    const getRef = new Date(startfrom);
    const difference = getRef - new Date();
    // const difference = new Date(component?.startfrom).getTime();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      setTimeExpired(true);
    }

    return timeLeft;
  }

  useEffect(() => {
    if (!timeExpired) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearInterval(timer);
    }
  }, []);

  return (
    <span className={className} id={id}>
      {
        (componentTimer) ? componentTimer(timeLeft, timeExpired) : <></>
      }
    </span>
  );
  
}

export default LoftyCountDown;
    
    