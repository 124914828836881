
import {
  ADD_TOKEN,
  ADD_USER,
  DELETE_TOKEN,

} from '../types/auth.types';

export const addLoftyToken = ({loftytoken, loftyuser}) => {
  localStorage.setItem('loftytoken', loftytoken);
  return {
    type: ADD_TOKEN,
    payload: {
      loftytoken, loftyuser,
    },
  };
};

export const deleteLoftytoken = () => {
  localStorage.removeItem('loftytoken');
  return {
    type: DELETE_TOKEN,
  };
};

export const addLoftyUser = (loftyuser) => ({
  type: ADD_USER,
  payload: {loftyuser},
});



