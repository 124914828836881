
import React, {useEffect, useState} from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';
import LoftyCountDown from '../../components/loftycountdown';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {customcrearharke_67351f269633993dfe9edac8 } from '../../api/cotizacion8c3iv.api'

import './formulariogzp3.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pformulariogzp3 = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id6735268ae110d14092e922b7" >

      <div id="iw4j" className="">
      
      <div id="ifxeud" className="">
      
      <div id="it2j54" className="">
      
      <div id="ips2" className="">
      
      <div
        id="idiuka"
        className=""
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
      
      <img
        className=""
        id="ismeq"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim3j34g0c.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="icq3pj" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/"
        type=""
      >
        <span>Inicio</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/vehiculos"
        type=""
      >
        <span>Búsqueda de Vehículos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/formulario"
        type=""
      >
        <span>Cotización</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div
        id="i29u1z"
        className=""
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
      
      <div id="itmdc" className="">
      
      <a
        target=""
        className="links "
        id="i2aqf"
        href="/"
        type="button"
      >
        
    <div id="i2g1w" className="">
    <span>Inicio</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="iwu1w"
        href="/vehiculos"
        type="button"
      >
        
    <div id="ib1qi" className="">
    <span>Búsqueda de Vehículos</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="i31ek"
        href="/formulario"
        type="button"
      >
        
    <div id="ipu5h" className="">
    <span>Cotización</span>
    </div>
    
      </a>
      
      </div>
      
      <div id="io767h" className="">
      
      <a
        target=""
        className="links "
        id="ibclo5"
        href="/"
        type="button"
      >
        
    <div id="i64dbt" className="">
    <span>Inicio</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="i28qdz"
        href="#"
        type="button"
      >
        
    <div id="i1xreu" className="">
    <span>Búsqueda de Vehículos</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="idayty"
        href="#"
        type="button"
      >
        
    <div id="i0vg2v" className="">
    <span>Cotización</span>
    </div>
    
      </a>
      
      </div>
      
      </div>
      
      </div>
      
      <div
        id="iwt1hr"
        className=""
        data-aos="fade-up"
        data-aos-duration="1000"
      >
      
      <div id="ihjr3o" className="">
      
      <p className="" id="iizaeu">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ijpl2e">
        <span>¡PRECIOS NUNCA ANTES VISTOS!</span>
      </span>
      
      </p>
      
      <p className="" id="iyinfc">
        
      <span className="" id="">
        
      </span>
      <span>Tasas desde 13% anual y financiamiento hasta 72 meses. ¡Preaprobación inmediata al llenar el formulario!</span>
      <br className="" id="" />
      
      </p>
      
      <div id="iozxpl" className="">
      
      <div id="itedql" className="">
      
      <div id="ivyo6u" className="">
      
        <div className="" id="iusqje">
          
      <form
        id="iqbpt8"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
						customcrearharke_67351f269633993dfe9edac8({
						tipov9p8x: e.target.tipov9p8x.value,
						departamento3v8psl: e.target.departamento3v8psl.value,
						nombreljaa1: e.target.nombreljaa1.value,
						apellidocvq1d: e.target.apellidocvq1d.value,
						identidadortnpfp8e: e.target.identidadortnpfp8e.value,
						telfonojwptc: e.target.telfonojwptc.value,
					});
					alert("Se envio exitosamente");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ihth4p" className="">
      
      <div id="ics90s" className="">
      
    <div id="ii6atx" className="">
    
      <label id="" className="">
      <span>Tipo</span>
      </label>
      
      <br className="" id="" />
      
        <select
          name="tipov9p8x"
          className="input "
          id="i5wlak"
        >
          <option value={`comercianteindividual`}>Comerciante Individual</option>
<option value={`Asalariado`}>Asalariado</option>
<option value={`otro`}>Otro</option>

        </select>
        
    </div>
    
      </div>
      
      <div id="il8713" className="">
      
    <div id="i91k7e" className="">
    
      <label id="" className="">
      <span>Departamento</span>
      </label>
      
      <br className="" id="" />
      
        <select
          name="departamento3v8psl"
          className="input "
          id="i7c7rq"
        >
          <option value={`franciscomorazan`}>Francisco Morazán</option>
<option value={`graciasadios`}>Gracias a Dios</option>
<option value={`copan`}>Copán</option>
<option value={`elparaiso`}>El Paraíso</option>
<option value={`Choluteca`}>Choluteca</option>
<option value={`atlantida`}>Atlántida</option>
<option value={`cortes`}>Cortés</option>
<option value={`colon`}>Colón</option>
<option value={`comayagua`}>Comayagua</option>
<option value={`lempira`}>Lempira</option>
<option value={`lapaz`}>La Paz</option>
<option value={`yoro`}>Yoro</option>
<option value={`valle`}>Valle</option>
<option value={`olancho`}>Olancho</option>
<option value={`santabarbara`}>Santa Bárbara</option>
<option value={`ocotepeque`}>Ocotepeque</option>
<option value={`isladelabahia`}>Isas de la Bahía</option>
<option value={`intibuca`}>Intibucá</option>

        </select>
        
    </div>
    
      </div>
      
      </div>
      
      <div id="ie8nca" className="">
      
      <div id="i4vebl" className="">
      
      <div id="ir6yms" className="">
      
      <label id="" className="">
      <span>Nombre</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="nombreljaa1"
        type="text"
        placeholder="Nombre"
        className="input "
        id="i4mwul"
      />
      
      </div>
      
      </div>
      
      <div id="ihaobl" className="">
      
      <div id="i4vxf5" className="">
      
      <label id="" className="">
      <span>Apellido</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="apellidocvq1d"
        type="text"
        placeholder="Apellido"
        className="input "
        id="il6dm3"
      />
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i3tb8i" className="">
      
      <label id="" className="">
      <span>Identidad o RTN</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="identidadortnpfp8e"
        type="text"
        placeholder="Identidad o RTN"
        className="input "
        id="i1aexa"
      />
      
      </div>
      
      <div id="ix08y7" className="">
      
      <label id="" className="">
      <span>Teléfono</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="telfonojwptc"
        type="text"
        placeholder="Teléfono"
        className="input "
        id="ioq1a4"
      />
      
      </div>
      
      <button
        type="submit"
        name=""
        id="ijfner"
        className="standard_button modificado "
        
      >
      
      <div id="iyflrx" className="">
      <span>Enviar</span>
      </div>
      
      </button>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      <img
        className=""
        id="i010iv"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim3j4s7un.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i3dh" className="">
      
      <p className="" id="ijh55q">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="igaurm">
        <span>CLIENTES SATISFECHOS</span>
      </span>
      
      </p>
      
      <p className="" id="i93l3">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="itg24k">
        <span>Conoce algunos de nuestros clientes que con cada foto, cuentan una historia de confianza y </span>
      <br className="" id="" />
      <span>satisfacción.</span>
      </span>
      
      </p>
      
      <div id="ihp5j" className="">
      
      <div id="ic3u6r" className="">
      
      <img
        className=""
        id="igiten"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37o7ctr.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i08g4i" className="">
      
      <img
        className=""
        id="ieb0gi"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37o88zy.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ily5z4" className="">
      
      <img
        className=""
        id="i3sgq6"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37o8a5a.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iv0g0l" className="">
      
      </div>
      
      <div id="i83rj4" className="">
      
      </div>
      
      <div id="ipjst4" className="">
      
      <div id="in4gsc" className="">
      
      <p className="" id="iy0bu7">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i6s1k4">
        <span>Búscanos en nuestras redes</span>
      </span>
      
      </p>
      
      <div id="ilijmz" className="">
      
        <a
          target=""
          className=""
          id="i68lj2"
          href="mailto:sac@carmaxhn.com"
        >
          
      <img
        className=""
        id="i137py"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q453x.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="iqu7o1"
          href="https://www.instagram.com/carmaxhn/"
        >
          
      <img
        className=""
        id="iru1nq"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q475b.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i6irgm"
          href="https://www.facebook.com/Carmaxhn"
        >
          
      <img
        className=""
        id="ix8eqg"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q465z.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="imqdmc" className="">
      
      <div id="is16l4" className="">
      
      <p className="" id="iqj6i5">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="itdh6f">
        <span>Búscanos en nuestras redes</span>
      </span>
      
      </p>
      
        <a
          target=""
          className=""
          id="iklg6x"
          href="#"
        >
          
        <a
          target=""
          className=""
          id="i9g4xj"
          href="#"
        >
          
      <img
        className=""
        id="irya7h"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q453x.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i7qfbt"
          href="#"
        >
          
      <img
        className=""
        id="i7gf77"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q465z.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i1dymt"
          href="#"
        >
          
      <img
        className=""
        id="i9veu5"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q475b.png"
        alt="undefined"
      />
      
        </a>
        
        </a>
        
      </div>
      
      </div>
      
      <div id="ilgjxb" className="">
      
      <p className="" id="iuyhhs">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i987ss">
        <span>Grupo corporativo econo © 2024 | Todos los Derechos Reservados</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pformulariogzp3);
  