
import React, {useEffect, useState} from 'react';


import {connect} from 'react-redux';
import LoftyDropdownComponent from '../../components/loftydropdowncomponent';
import LoftyCountDown from '../../components/loftycountdown';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {custombusquedabasicadevehiculos3iq75_673733bf9633993dfe9ee251 } from '../../api/vehiculosai6rp.api'

import './paginadebusquedadevehiculosh7n0y.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Ppaginadebusquedadevehiculosh7n0y = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {
	const [loftystate_inputbusqueda, set_loftystate_inputbusqueda] = useState()
	const [loftystate_textobusqueda, set_loftystate_textobusqueda] = useState()
	const [loftystate_salto, set_loftystate_salto] = useState()

	const [loftyres_673733bf9633993dfe9ee251_component_if2ua7, setloftyres_673733bf9633993dfe9ee251_component_if2ua7] = useState([]);

useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);

	useEffect(() => {
		const handleLoftyData = async () => {
			try {
				const responseLoftyApps = await custombusquedabasicadevehiculos3iq75_673733bf9633993dfe9ee251({
					nombregdtv6: loftystate_textobusqueda,
				});
				setloftyres_673733bf9633993dfe9ee251_component_if2ua7(responseLoftyApps.data);
			} catch (loftyerror) {
			}
		}
		handleLoftyData();
	}, [loftystate_textobusqueda, ]);

	function function_aplicarbusqueda8n607_673512cce110d14092e91ef7() {
		set_loftystate_textobusqueda(loftystate_inputbusqueda);
	}

	function function_aplicarbusquedadosns86gi_6735340be110d14092e92a38() {
		set_loftystate_inputbusqueda(loftystate_textobusqueda);
	}


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id67350d09e110d14092e919fc" >

      <div id="iw4j" className="">
      
      <div id="ifxeud" className="">
      
      <div id="it2j54" className="">
      
      <div id="ips2" className="">
      
      <div
        id="idiuka"
        className=""
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
      
      <img
        className=""
        id="ismeq"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim3j34g0c.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="icq3pj" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/"
        type=""
      >
        <span>Inicio</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/vehiculos"
        type=""
      >
        <span>Busqueda de Vehiculos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/formulario"
        type=""
      >
        <span>Cotización</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div
        id="i29u1z"
        className=""
        data-aos="zoom-in-up"
        data-aos-duration="1000"
      >
      
      <div id="itmdc" className="">
      
      <a
        target=""
        className="links "
        id="i2aqf"
        href="/"
        type="button"
      >
        
    <div id="i2g1w" className="">
    <span>Inicio</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="iwu1w"
        href="/vehiculos"
        type="button"
      >
        
    <div id="ib1qi" className="">
    <span>Búsqueda de Vehículos</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="i31ek"
        href="/formulario"
        type="button"
      >
        
    <div id="ipu5h" className="">
    <span>Cotización</span>
    </div>
    
      </a>
      
      </div>
      
      <div id="io767h" className="">
      
      <a
        target=""
        className="links "
        id="ibclo5"
        href="/"
        type="button"
      >
        
    <div id="i64dbt" className="">
    <span>Inicio</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="i28qdz"
        href="#"
        type="button"
      >
        
    <div id="i1xreu" className="">
    <span>Búsqueda de Vehículos</span>
    </div>
    
      </a>
      
      <a
        target=""
        className="links "
        id="idayty"
        href="#"
        type="button"
      >
        
    <div id="i0vg2v" className="">
    <span>Cotización</span>
    </div>
    
      </a>
      
      </div>
      
      </div>
      
      </div>
      
      <div
        id="iwt1hr"
        className=""
        data-aos="fade-up"
        data-aos-duration="1000"
      >
      
      <div id="ihjr3o" className="">
      
      <p className="" id="iizaeu">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ijpl2e">
        <span>Buscar auto</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="isdi14" className="">
      
      <div id="iycggk" className="">
      
      <p className="" id="izs44f">
        <span>Ingrese el texto para la búsqueda</span>
      </p>
      
        <div id="undefined" className="inputContainer ">
          
        <input
          name=""
          type="text"
          placeholder="Nombre o marca de vehiculo"
          className="input "
          id="iqbmjg"
          onChange={(e) => {
            set_loftystate_inputbusqueda(e.target.value);
          }}
          value={loftystate_inputbusqueda}
        />
        
        </div>
      
      </div>
      
      <div id="itoydk" className="">
      
      <button
        type=""
        name=""
        id="ifmzgs"
        className="button-secondary "
        onClick={async () => {
		function_aplicarbusqueda8n607_673512cce110d14092e91ef7();
}}

      >
      
      <div id="itdgy4" className="">
      <span>Aplicar</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i6xx" className="">
      
      <div id="iir65o" className="">
      
      <p className="" id="igqyi">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="itiygt">
        <span>Resultados</span>
      </span>
      
      </p>
      
      </div>
      
        <div id="if2ua7" className="">
        
          {
            loftyres_673733bf9633993dfe9ee251_component_if2ua7.map((loftyitem_673733bf9633993dfe9ee251_component_if2ua7) => (
              <div key={loftyitem_673733bf9633993dfe9ee251_component_if2ua7._id} className="" id="ivi7z8">
                
      <div id="i5gjog" className="">
      
      <div id="i0n7fr" className="">
      
        <img
          className=""
          id="i503nx"
          alt=""
          src={loftyitem_673733bf9633993dfe9ee251_component_if2ua7.loftyUrl}
        />
        
      </div>
      
      <div id="isixvu" className="">
      
        <div className="" id="itat2e">
          
          <div className="" id="iibt0g">
            {loftyitem_673733bf9633993dfe9ee251_component_if2ua7?.nombrevehiculo5e65h}
          </div>
          
        </div>
        
        <a
          target=""
          className="standard_button "
          id="iypy3h"
          href="/formulario"
        >
          
      <div id="ik70km" className="">
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="ihdfug">
        <span>Cotizar Ahora</span>
      </span>
      
      </div>
      
        </a>
        
      </div>
      
      </div>
      
              </div>
            ))
          }
          
        </div>
        
      </div>
      
      <div id="i7x69l" className="">
      
      </div>
      
      <div id="i83rj4" className="">
      
      </div>
      
      <div id="ipjst4" className="">
      
      <div id="in4gsc" className="">
      
      <p className="" id="iy0bu7">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i6s1k4">
        <span>Búscanos en nuestras redes</span>
      </span>
      
      </p>
      
      <div id="ilijmz" className="">
      
        <a
          target=""
          className=""
          id="i68lj2"
          href="mailto:sac@carmaxhn.com"
        >
          
      <img
        className=""
        id="i137py"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q453x.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="iqu7o1"
          href="https://www.instagram.com/carmaxhn/"
        >
          
      <img
        className=""
        id="iru1nq"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q475b.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i6irgm"
          href="https://www.facebook.com/Carmaxhn"
        >
          
      <img
        className=""
        id="ix8eqg"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q465z.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="imqdmc" className="">
      
      <div id="is16l4" className="">
      
      <p className="" id="iqj6i5">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="itdh6f">
        <span>Búscanos en nuestras redes</span>
      </span>
      
      </p>
      
        <a
          target=""
          className=""
          id="iklg6x"
          href="#"
        >
          
        <a
          target=""
          className=""
          id="i9g4xj"
          href="#"
        >
          
      <img
        className=""
        id="irya7h"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q453x.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i7qfbt"
          href="#"
        >
          
      <img
        className=""
        id="i7gf77"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q465z.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i1dymt"
          href="#"
        >
          
      <img
        className=""
        id="i9veu5"
        src="https://assetsprojects.s3.amazonaws.com/404mwaim37q475b.png"
        alt="undefined"
      />
      
        </a>
        
        </a>
        
      </div>
      
      </div>
      
      <div id="ilgjxb" className="">
      
      <p className="" id="iuyhhs">
        
      <span className="" id="">
        
      </span>
      
      <span className="" id="">
        
      </span>
      
      <span className="" id="i987ss">
        <span>Grupo corporativo econo © 2024 | Todos los Derechos Reservados</span>
      </span>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Ppaginadebusquedadevehiculosh7n0y);
  