
import axios from '../config/axios';

export const url = '/api/cotizacion8c3iv'


export const customcrearharke_67351f269633993dfe9edac8 = ({
  tipov9p8x,
	nombreljaa1,
	apellidocvq1d,
	identidadortnpfp8e,
	telfonojwptc,
	departamento3v8psl,

}) => new Promise((resolve, reject) => {
  if (tipov9p8x && nombreljaa1 && apellidocvq1d && identidadortnpfp8e && telfonojwptc && departamento3v8psl) {
    axios.post(`${url}/custom/`, {
      tipov9p8x,
			nombreljaa1,
			apellidocvq1d,
			identidadortnpfp8e,
			telfonojwptc,
			departamento3v8psl,

    },
    ).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});



